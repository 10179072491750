"use client"

export default function NotFoundPage() {
  return (
    <div className=" m-auto text-center justify-self-center">
      <h1 className="text-2xl font-bold pb-2">404 - Seite nicht gefunden</h1>
      <h2>aber es gibt viele andere Seiten zu entdecken</h2>
    </div>
  )
}
